<template>
    <div>
        <table class="table grid-table">
            <thead>
                <tr>
                    <!-- MultiSelect -->
                    <th
                        v-if="rowSelector"
                        class="col-min"
                    >
                        <y-form-checkbox
                            label-for="selectAll"
                            @input="handleSelectAll"
                        />
                    </th>

                    <!-- Index -->
                    <th
                        v-else
                        class="col-min"
                        v-html="`#`"
                    />

                    <th
                        v-for="column in tableColumns"
                        :key="`table-column-${column.id}`"
                        :class="{'col-min': column.min}"
                    >
                        {{ column.title }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(row, id, rowCount) in grid.list"
                    :key="`table-row-${id}`"
                    :class="{'loading': $wait.is(`row-${id}-loading`)}"
                >
                    <!-- MultiSelect -->
                    <td
                        v-if="rowSelector"
                        class="min"
                    >
                        <y-form-checkbox
                            v-model="selected[id]"
                            no-label
                            :name="`row-${id}`"
                        />
                    </td>

                    <!-- Index -->
                    <td
                        v-else
                        class="min"
                    >
                        {{ rowNumber(rowCount) | digits }}
                    </td>

                    <td
                        v-for="(item, index) in row"
                        :key="`row-${id}-cell-${index}`"
                    >
                        <y-form
                            v-if="(index !== row.length - 1) || !showRefreshButton"
                            :params="item"
                        />

                        <div
                            v-else-if="showRefreshButton"
                            class="row-item-refresh"
                        >
                            <y-button
                                type="button"
                                icon="md-refresh"
                                aria-label="Refresh"
                                size="sm"
                                @click.prevent="refresh(id)"
                            />
                            <y-form :params="item" />
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination -->
        <div class="pagination-row">
            <span />

            <y-pagination
                :model="paginationModel"
                :per-page="paginationModel.per_page || 15"
                route="content-type-list"
                @paginate="$emit('paginate')"
            />

            <span class="total">
                {{ $t('grid.total', { perPage, total }) | digits }}
            </span>
        </div>
    </div>
</template>

<script>

    import { YForm, YFormCheckbox } from '@deps';
    import YPagination from '@deps/Pagination';

    export default {

        name: 'GridTable',

        components: {
            YForm,
            YFormCheckbox,
            YPagination,
        },

        props: {
            grid: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                selected: this.grid.selected || {},
            };
        },

        computed: {

            /**
             * Return table of grid
             */
            table() {
                return this.grid.table;
            },

            /**
             * Return table of grid
             */
            rowSelector() {
                return this.get(this.grid, 'header.row_selector');
            },

            /**
             * Return table of grid
             */
            paginationModel() {
                return this.grid.paginationModel;
            },

            /**
             * Return total rows
             */
            total() {
                return this.get(this.paginationModel, 'total');
            },

            /**
             * Return total rows
             */
            perPage() {
                return this.get(this.paginationModel, 'count');
            },

            /**
             * Get table columns titles
             */
            tableColumns() {
                return Object.keys(this.table.columns).map((item) => ({
                    id   : this.table.columns[item].id,
                    title: this.table.columns[item].title,
                    min  : this.table.columns[item].min_size,
                }));
            },

            /**
             * Check if the table rows should have refresh button
             */
            showRefreshButton() {
                return this.table.refresh_button;
            },

        },

        methods: {

            /**
             * Handle select all
             *
             * @param checked
             */
            handleSelectAll(checked) {
                Object.keys(this.grid.selected).forEach((id) => this.$set(this.grid.selected, id, checked));
            },

            /**
             * Row number
             *
             * @param row
             */
            rowNumber(row) {
                return (this.paginationModel.current_page - 1) * this.paginationModel.per_page + row + 1;
            },

            /**
             * Refresh row
             *
             * @param id
             */
            refresh(id) {
                this.$emit('refresh', { refresh: id });
            },

        },

    };
</script>
